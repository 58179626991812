"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommandShortcut = exports.CommandSeparator = exports.CommandList = exports.CommandItem = exports.CommandInput = exports.CommandGroup = exports.CommandEmpty = exports.CommandDialog = exports.Command = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var dialog_1 = require("@/components/ui/dialog");
var utils_1 = require("@/lib/utils");
var cmdk_1 = require("cmdk");
var React = __importStar(require("react"));
var General_1 = require("../molecules/icons/General");
var Command = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)(cmdk_1.Command, __assign({ ref: ref, className: (0, utils_1.cn)('tw-flex tw-h-full tw-w-full tw-flex-col tw-overflow-hidden tw-rounded-md', className) }, props)));
});
exports.Command = Command;
Command.displayName = cmdk_1.Command.displayName;
var CommandDialog = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return ((0, jsx_runtime_1.jsx)(dialog_1.Dialog, __assign({}, props, { children: (0, jsx_runtime_1.jsx)(dialog_1.DialogContent, { className: 'tw-overflow-hidden tw-p-0 tw-shadow-lg', children: (0, jsx_runtime_1.jsx)(Command, { className: 'tw-[&_[cmdk-group-heading]]:tw-px-2 [&_[cmdk-group-heading]]:tw-font-medium [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:tw-pt-0 [&_[cmdk-group]]:tw-px-2 [&_[cmdk-input-wrapper]_svg]:tw-h-5 [&_[cmdk-input-wrapper]_svg]:tw-w-5 [&_[cmdk-input]]:tw-h-12 [&_[cmdk-item]]:tw-px-2 [&_[cmdk-item]]:tw-py-3 [&_[cmdk-item]_svg]:tw-h-5 [&_[cmdk-item]_svg]:tw-w-5', children: children }) }) })));
};
exports.CommandDialog = CommandDialog;
var CommandInput = React.forwardRef(function (_a, ref) {
    var className = _a.className, value = _a.value, hideIcon = _a.hideIcon, icon = _a.icon, props = __rest(_a, ["className", "value", "hideIcon", "icon"]);
    var _b = React.useState(value), input = _b[0], setInput = _b[1];
    return ((0, jsx_runtime_1.jsxs)("div", { className: (0, utils_1.cn)('tw-relative', !hideIcon ? 'tw-flex tw-items-center tw-border-b tw-border-customgrey tw-px-3' : ''), "cmdk-input-wrapper": '', children: [(0, jsx_runtime_1.jsx)(cmdk_1.Command.Input, __assign({ ref: ref, className: (0, utils_1.cn)('tw-flex tw-w-full tw-rounded-md tw-bg-background-1 tw-py-2 tw-text-sm tw-text-text-1 tw-outline-none placeholder:tw-text-customgrey disabled:tw-cursor-not-allowed disabled:tw-opacity-50', icon ? '!tw-pl-8' : '', className) }, props, { value: input, onValueChange: function (val) {
                    setInput(val);
                } })), icon && (0, jsx_runtime_1.jsx)("div", { className: 'tw-absolute tw-left-2 tw-top-[27%]', children: icon }), !hideIcon ? ((0, jsx_runtime_1.jsx)("div", { className: 'tw-ml-2 tw-flex tw-h-2 tw-w-2 tw-scale-95 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full tw-bg-transparent tw-p-2.5 tw-transition tw-duration-100 tw-ease-in-out hover:tw-scale-100 hover:tw-bg-background-3', onClick: function () {
                    setInput('');
                }, children: (0, jsx_runtime_1.jsx)(General_1.CrossSVG, { className: 'tw-shrink-0 tw-fill-text-4' }) })) : null] }));
});
exports.CommandInput = CommandInput;
CommandInput.displayName = cmdk_1.Command.Input.displayName;
var CommandList = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (0, jsx_runtime_1.jsx)(cmdk_1.Command.List, __assign({ ref: ref, className: (0, utils_1.cn)('tw-p-0', className) }, props));
});
exports.CommandList = CommandList;
CommandList.displayName = cmdk_1.Command.List.displayName;
var CommandEmpty = React.forwardRef(function (props, ref) { return ((0, jsx_runtime_1.jsx)(cmdk_1.Command.Empty, __assign({ ref: ref, className: 'tw-py-6 tw-text-center tw-text-sm tw-text-text-1' }, props))); });
exports.CommandEmpty = CommandEmpty;
CommandEmpty.displayName = cmdk_1.Command.Empty.displayName;
var CommandGroup = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)(cmdk_1.Command.Group, __assign({ ref: ref, className: (0, utils_1.cn)('tw-&_[cmdk-group-heading]]:tw-text-xs tw-overflow-hidden tw-text-text-1 [&_[cmdk-group-heading]]:tw-px-2 [&_[cmdk-group-heading]]:tw-py-1.5 [&_[cmdk-group-heading]]:tw-font-medium [&_[cmdk-group-heading]]:tw-text-text-1', className) }, props)));
});
exports.CommandGroup = CommandGroup;
CommandGroup.displayName = cmdk_1.Command.Group.displayName;
var CommandSeparator = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)(cmdk_1.Command.Separator, __assign({ ref: ref, className: (0, utils_1.cn)('-tw-mx-1 tw-h-px tw-bg-customgrey', className) }, props)));
});
exports.CommandSeparator = CommandSeparator;
CommandSeparator.displayName = cmdk_1.Command.Separator.displayName;
var CommandItem = React.forwardRef(function (_a, ref) {
    var className = _a.className, hideHover = _a.hideHover, props = __rest(_a, ["className", "hideHover"]);
    return ((0, jsx_runtime_1.jsx)(cmdk_1.Command.Item, __assign({ ref: ref, className: (0, utils_1.cn)('tw-relative tw-flex tw-cursor-pointer tw-select-none tw-items-center tw-rounded-sm tw-px-2 tw-py-1.5 tw-text-sm tw-outline-none tw-transition-all tw-duration-150 tw-ease-in-out aria-selected:tw-text-text-1 data-[disabled]:tw-pointer-events-none data-[disabled]:tw-opacity-50', hideHover ? '' : 'aria-selected:tw-bg-background-3', className) }, props)));
});
exports.CommandItem = CommandItem;
CommandItem.displayName = cmdk_1.Command.Item.displayName;
var CommandShortcut = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (0, jsx_runtime_1.jsx)("span", __assign({ className: (0, utils_1.cn)('tw-ml-auto tw-text-xs tw-tracking-widest tw-text-black', className) }, props));
};
exports.CommandShortcut = CommandShortcut;
CommandShortcut.displayName = 'CommandShortcut';
