"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToastViewport = exports.ToastTitle = exports.ToastProvider = exports.ToastDescription = exports.ToastClose = exports.ToastAction = exports.Toast = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var utils_1 = require("@/lib/utils");
var ToastPrimitives = __importStar(require("@radix-ui/react-toast"));
var class_variance_authority_1 = require("class-variance-authority");
var React = __importStar(require("react"));
var General_1 = require("../molecules/icons/General");
var ToastProvider = ToastPrimitives.Provider;
exports.ToastProvider = ToastProvider;
var ToastViewport = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)(ToastPrimitives.Viewport, __assign({ ref: ref, className: (0, utils_1.cn)('tw-fixed tw-right-0 tw-top-0 tw-z-[100] tw-flex tw-max-h-screen tw-w-full tw-flex-col-reverse tw-p-4 sm:tw-right-0 sm:tw-flex-col md:tw-max-w-[420px]', className) }, props)));
});
exports.ToastViewport = ToastViewport;
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;
var toastVariants = (0, class_variance_authority_1.cva)('tw-group tw-pointer-events-auto tw-relative tw-flex tw-w-full tw-items-center tw-space-x-4 tw-overflow-hidden tw-border-l-[6px] tw-rounded-md tw-p-6 tw-pr-8 tw-shadow-lg tw-transition-all !tw-duration-500 data-[swipe=cancel]:tw-translate-x-0 data-[swipe=end]:tw-translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:tw-translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:tw-transition-none data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[swipe=end]:tw-animate-out data-[state=closed]:tw-fade-out-80 data-[state=closed:tw-slide-out-to-right-full data-[state=open]:tw-slide-in-from-top-full data-[state=open]:sm:tw-slide-in-from-bottom-full tw-text-text-1', {
    variants: {
        variant: {
            success: 'tw-bg-background-3 tw-border-success-1',
            danger: 'tw-bg-background-3 tw-border-error-1',
            info: 'tw-bg-background-3 tw-border-[#E5971D]',
        },
    },
    defaultVariants: {
        variant: 'success',
    },
});
var Toast = React.forwardRef(function (_a, ref) {
    var className = _a.className, variant = _a.variant, children = _a.children, props = __rest(_a, ["className", "variant", "children"]);
    return ((0, jsx_runtime_1.jsxs)(ToastPrimitives.Root, __assign({ ref: ref, className: (0, utils_1.cn)(toastVariants({ variant: variant }), className) }, props, { children: [variant === 'success' ? (0, jsx_runtime_1.jsx)(General_1.SuccessSVG, {}) : variant === 'info' ? (0, jsx_runtime_1.jsx)(General_1.InfoSVG, { color: '#E5971D' }) : (0, jsx_runtime_1.jsx)(General_1.ErrorSVG, {}), children] })));
});
exports.Toast = Toast;
Toast.displayName = ToastPrimitives.Root.displayName;
var ToastAction = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)(ToastPrimitives.Action, __assign({ ref: ref, className: (0, utils_1.cn)('tw-bg-transparenttw-px-3 tw-inline-flex tw-h-8  tw-shrink-0 tw-items-center tw-justify-center tw-rounded-md tw-border tw-text-sm tw-font-medium tw-transition-colors disabled:tw-pointer-events-none disabled:tw-opacity-50', className) }, props)));
});
exports.ToastAction = ToastAction;
ToastAction.displayName = ToastPrimitives.Action.displayName;
var ToastClose = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)(ToastPrimitives.Close, __assign({ ref: ref, className: (0, utils_1.cn)('tw-absolute tw-right-5 tw-top-1/2 -tw-translate-y-1/2 tw-rounded-md tw-transition-all tw-duration-200 tw-ease-in-out hover:tw-scale-110', className), "toast-close": '' }, props, { children: (0, jsx_runtime_1.jsx)(General_1.CrossXSVG, { className: '!tw-size-[16px]' }) })));
});
exports.ToastClose = ToastClose;
ToastClose.displayName = ToastPrimitives.Close.displayName;
var ToastTitle = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)(ToastPrimitives.Title, __assign({ ref: ref, className: (0, utils_1.cn)('tw-text-sm tw-font-semibold tw-text-text-1', className) }, props)));
});
exports.ToastTitle = ToastTitle;
ToastTitle.displayName = ToastPrimitives.Title.displayName;
var ToastDescription = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)(ToastPrimitives.Description, __assign({ ref: ref, className: (0, utils_1.cn)('tw-text-xs tw-text-text-2', className) }, props)));
});
exports.ToastDescription = ToastDescription;
ToastDescription.displayName = ToastPrimitives.Description.displayName;
