"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var button_1 = require("@/components/ui/button");
var react_1 = require("react");
var angular_bridge_1 = require("../../../helpers/angular-bridge");
var HubspotPush = function (_a) {
    var activeServer = _a.activeServer, paragon = _a.paragon;
    if (!activeServer || (activeServer && activeServer.user && activeServer.user.length === 1)) {
        return (0, jsx_runtime_1.jsx)("div", { children: "No data to push." });
    }
    var _b = (0, react_1.useState)(true), buttonState = _b[0], setButtonState = _b[1];
    var tableColumns = Object.keys(activeServer === null || activeServer === void 0 ? void 0 : activeServer.user[0]).map(function (k) { return k; });
    if (!tableColumns.includes('country')) {
        tableColumns.push('country');
    }
    if (!tableColumns.includes('phone')) {
        tableColumns.push('phone');
    }
    var userData = activeServer.user;
    var handlePushEvent = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var first, last, email, country, phone, validationOptions, response, error_1, validationOptions, response, error_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    console.log("data", data);
                    setButtonState(false);
                    first = data.first, last = data.last, email = data.email, country = data.country, phone = data.phone;
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 8]);
                    validationOptions = {
                        method: 'POST',
                        headers: {},
                        body: {
                            properties: {
                                firstname: first,
                                lastname: last,
                                email: email,
                                country: country || '',
                                phone: phone ? phone[0] : '',
                                hub_member: "YES",
                            }
                        },
                    };
                    return [4 /*yield*/, paragon.request('hubspot', '/crm/v3/objects/contacts', validationOptions)];
                case 2:
                    response = _b.sent();
                    if (response) {
                        (0, angular_bridge_1.NavigateTo)({ type: 'success', message: 'This contact has been pushed to Hubspot' }, 'toast');
                        setButtonState(true);
                    }
                    return [3 /*break*/, 8];
                case 3:
                    error_1 = _b.sent();
                    _b.label = 4;
                case 4:
                    _b.trys.push([4, 6, , 7]);
                    validationOptions = {
                        method: 'PATCH',
                        headers: {},
                        body: {
                            properties: {
                                firstname: first,
                                lastname: last,
                                country: country || '',
                                phone: phone ? phone[0] : '',
                                hub_member: "YES",
                            }
                        },
                    };
                    return [4 /*yield*/, paragon.request('hubspot', "/crm/v3/objects/contacts/".concat(email, "?idProperty=email"), validationOptions)];
                case 5:
                    response = _b.sent();
                    if (response) {
                        (0, angular_bridge_1.NavigateTo)({ type: 'success', message: 'This contact has been updated to Hubspot' }, 'toast');
                        setButtonState(true);
                    }
                    return [3 /*break*/, 7];
                case 6:
                    error_2 = _b.sent();
                    if ((_a = error_2 === null || error_2 === void 0 ? void 0 : error_2.message) === null || _a === void 0 ? void 0 : _a.includes(409)) {
                        (0, angular_bridge_1.NavigateTo)({ type: 'error', message: 'This contact has been synced already' }, 'toast');
                    }
                    else {
                        (0, angular_bridge_1.NavigateTo)({ type: 'error', message: error_2.message }, 'toast');
                    }
                    setButtonState(true);
                    return [3 /*break*/, 7];
                case 7: return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    return ((0, jsx_runtime_1.jsx)("div", { className: 'tw-w-full tw-mt-4 tw-px-4 tw-py-2 tw-shadow-md tw-rounded-lg', children: (userData === null || userData === void 0 ? void 0 : userData.length) > 0 && ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-mt-4 tw-px-4 tw-py-2 tw-shadow-md tw-rounded-lg tw-overflow-x-auto', children: [(0, jsx_runtime_1.jsx)("h3", { className: 'tw-font-semibold tw-text-lg tw-text-text-1 tw-mb-2', children: "Community Users" }), (0, jsx_runtime_1.jsxs)("table", { className: 'tw-min-w-full tw-border tw-border-background-4', children: [(0, jsx_runtime_1.jsx)("thead", { className: 'tw-bg-background-6', children: (0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("th", { className: 'tw-px-4 tw-py-2 tw-border tw-border-background-4 tw-text-left tw-text-sm tw-font-medium tw-text-text-1', children: "Action" }), tableColumns === null || tableColumns === void 0 ? void 0 : tableColumns.map(function (column, index) {
                                        return ['name', 'email', 'country', 'phone'].includes(column) && ((0, jsx_runtime_1.jsx)("th", { className: 'tw-px-4 tw-py-2 tw-border tw-border-background-4 tw-text-left tw-text-sm tw-font-medium tw-text-text-1', children: column }, index));
                                    })] }) }), (0, jsx_runtime_1.jsx)("tbody", { children: userData === null || userData === void 0 ? void 0 : userData.map(function (row, rowIndex) {
                                var _a, _b, _c;
                                return ((0, jsx_runtime_1.jsxs)("tr", { className: 'tw-border-b tw-border-background-2 hover:tw-bg-background-3', children: [(0, jsx_runtime_1.jsx)("td", { className: 'tw-px-4 tw-py-2 tw-text-sm tw-text-text-2', children: (0, jsx_runtime_1.jsx)(button_1.Button, { className: 'mt-2 !font-bold tw-h-9 tw-w-24', onClick: function () { return handlePushEvent(row); }, disabled: !buttonState, children: "Sync" }) }), (0, jsx_runtime_1.jsx)("td", { className: 'tw-px-4 tw-py-2 tw-text-sm tw-text-text-2', children: ((_c = (_b = (_a = row === null || row === void 0 ? void 0 : row.gdpr) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.allowed) === null || _c === void 0 ? void 0 : _c.includes('Email')) ? row.email : '********' }), (0, jsx_runtime_1.jsx)("td", { className: 'tw-px-4 tw-py-2 tw-text-sm tw-text-text-2', children: row.name }), (0, jsx_runtime_1.jsx)("td", { className: 'tw-px-4 tw-py-2 tw-text-sm tw-text-text-2', children: (row === null || row === void 0 ? void 0 : row.country) || '-' }), (0, jsx_runtime_1.jsx)("td", { className: 'tw-px-4 tw-py-2 tw-text-sm tw-text-text-2', children: (row === null || row === void 0 ? void 0 : row.phone) || '-' })] }, rowIndex));
                            }) })] })] })) }));
};
exports.default = HubspotPush;
