"use strict";
'use client';
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var radio_group_1 = require("@/components/ui/radio-group");
var utils_1 = require("@/lib/utils");
var react_1 = require("react");
/**
 * Radio Group Component
 * @description Example usage can be found at `BannerForm.tsx`
 */
function CustomRadioGroup(_a) {
    var value = _a.value, options = _a.options, onChange = _a.onChange, disabled = _a.disabled, className = _a.className, label = _a.label;
    return ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("label", { className: 'tw-text-sm tw-font-bold tw-text-text-1', children: label }), (0, jsx_runtime_1.jsx)(radio_group_1.RadioGroup, { defaultValue: value, disabled: disabled, className: (0, utils_1.cn)(disabled ? 'tw-cursor-not-allowed' : '', className), children: options.map(function (opt) { return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-space-x-2', children: [(0, jsx_runtime_1.jsx)(radio_group_1.RadioGroupItem, { value: opt.value, id: opt.value, onClick: function () { return onChange(opt.value); } }), (0, jsx_runtime_1.jsx)("label", { className: (0, utils_1.cn)('tw-inline-block tw-flex-1', disabled ? 'tw-cursor-not-allowed tw-opacity-50' : 'tw-cursor-pointer', 'tw-text-sm', value === opt.value ? 'tw-text-text-1' : 'tw-text-text-4'), htmlFor: opt.value, children: opt.text })] }, opt.value)); }) })] }));
}
exports.default = CustomRadioGroup;
