"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var General_1 = require("@/components/molecules/icons/General");
var utils_1 = require("@/lib/utils");
/**
 * Counter Input with `plus` and `minus` button
 */
function CounterInput(_a) {
    var value = _a.value, onChange = _a.onChange, min = _a.min, max = _a.max, disabled = _a.disabled, className = _a.className, borderLight = _a.borderLight, label = _a.label, _b = _a.showOptional, showOptional = _b === void 0 ? false : _b;
    var handleChange = function (type) {
        switch (type) {
            case 'plus':
                onChange(value + 1);
                break;
            case 'minus':
                onChange(value - 1);
                break;
            default:
                break;
        }
    };
    var handleBlur = function () {
        onChange(value >= max ? max : value <= min ? min : value);
    };
    return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-relative tw-flex tw-flex-col tw-items-start', children: [label ? ((0, jsx_runtime_1.jsxs)("label", { className: 'tw-mb-1 tw-text-sm tw-font-[500] tw-text-text-1', children: [label, showOptional && (0, jsx_runtime_1.jsx)("span", { className: 'tw-ml-1 tw-text-xs tw-text-text-4', children: "(Optional)" })] })) : null, (0, jsx_runtime_1.jsxs)("div", { className: (0, utils_1.cn)('tw-flex tw-h-[40px] tw-w-[117.5px] tw-items-center tw-rounded tw-border tw-border-background-7', disabled && 'tw-opacity-80', className, borderLight ? 'tw-border-text-4' : ''), children: [(0, jsx_runtime_1.jsx)("input", { className: (0, utils_1.cn)('tw-h-full tw-w-[80%] tw-bg-transparent tw-pl-[10px] tw-text-sm tw-text-text-1 tw-outline-none', disabled ? 'tw-cursor-not-allowed tw-opacity-80' : 'tw-cursor-text tw-opacity-100'), type: 'number', min: min, disabled: disabled, max: max, value: Number(value), onChange: function (e) {
                            onChange(Number(e.target.value));
                        }, onWheel: function (e) { return e.currentTarget.blur(); }, onBlur: handleBlur, "aria-labelledby": 'counter input' }), (0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-h-full tw-w-[20%] tw-flex-col tw-items-start', children: [(0, jsx_runtime_1.jsx)("button", { onClick: function () { return handleChange('plus'); }, "aria-label": 'Plus button', disabled: disabled || value >= max, className: (0, utils_1.cn)('tw-flex tw-h-[50%] tw-w-full tw-items-center tw-justify-center tw-border-b tw-border-l tw-border-background-7', disabled || value >= max
                                    ? 'tw-cursor-not-allowed tw-bg-opacity-80 hover:tw-opacity-100'
                                    : 'hover:tw-opacity-80', borderLight ? 'tw-border-text-4' : ''), children: (0, jsx_runtime_1.jsx)(General_1.AddSVG, {}) }), (0, jsx_runtime_1.jsx)("button", { onClick: function () { return handleChange('minus'); }, "aria-label": 'Minus button', disabled: disabled || value <= min, className: (0, utils_1.cn)('tw-flex tw-h-[50%] tw-w-full tw-items-center tw-justify-center tw-border-l tw-border-background-7', disabled || value <= min
                                    ? 'tw-cursor-not-allowed tw-opacity-80 hover:tw-opacity-100'
                                    : 'hover:tw-opacity-80', borderLight ? 'tw-border-text-4' : ''), children: (0, jsx_runtime_1.jsx)(General_1.MinusSVG, {}) })] })] })] }));
}
exports.default = CounterInput;
