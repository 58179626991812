"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var Input_1 = require("@/components/atoms/Inputs/Input");
var TextArea_1 = require("@/components/atoms/Inputs/TextArea");
var button_1 = require("@/components/ui/button");
var dialog_1 = require("@/components/ui/dialog");
var language_1 = require("@v2/libraries/language");
var react_1 = require("react");
function AddUserProfileModal(_a) {
    var shouldShow = _a.shouldShow, onClose = _a.onClose, saveHandler = _a.saveHandler;
    var name = (0, react_1.useRef)(null);
    var description = (0, react_1.useRef)(null);
    var _b = (0, react_1.useState)(true), isError = _b[0], setIsError = _b[1];
    var handleInputChange = (0, react_1.useCallback)(function () {
        var _a, _b;
        var nameValue = ((_a = name.current) === null || _a === void 0 ? void 0 : _a.value.trim()) || '';
        var descriptionValue = ((_b = description.current) === null || _b === void 0 ? void 0 : _b.value.trim()) || '';
        setIsError(!(nameValue && descriptionValue));
    }, []);
    var createNewProfile = (0, react_1.useCallback)(function () {
        if (name.current && description.current) {
            var nameValue = name.current.value;
            var descriptionValue = description.current.value;
            console.log('Name:', nameValue);
            console.log('Description:', descriptionValue);
            // You can now use nameValue and descriptionValue as needed
            // For example, send them to an API or update the state
            saveHandler({
                name: nameValue,
                desc: descriptionValue,
            });
            onClose();
        }
        else {
            setIsError(true);
            console.error('Name or Description is missing.');
        }
    }, []);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(dialog_1.Dialog, { open: shouldShow, onOpenChange: onClose, children: (0, jsx_runtime_1.jsxs)(dialog_1.DialogContent, { className: "tw-overflow-hidden !tw-p-0", hideClose: true, children: [(0, jsx_runtime_1.jsx)(dialog_1.DialogHeader, { className: "tw-border-b tw-border-customgrey tw-px-6 tw-py-6", children: (0, jsx_runtime_1.jsx)(dialog_1.DialogTitle, { className: "tw-text-left tw-text-lg", children: "Add User Profile Tab" }) }), (0, jsx_runtime_1.jsxs)("section", { id: "section", className: "tw-mb-3 tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-y-6 tw-px-8", children: [(0, jsx_runtime_1.jsx)(Input_1.Input, { label: "Name", placeholder: "Enter Name", ref: name, onChange: handleInputChange }), (0, jsx_runtime_1.jsx)(TextArea_1.Textarea, { id: "userProfileDesc", labelClassName: "tw-font-bold", label: "Description of Page", ref: description, onChange: handleInputChange })] }), (0, jsx_runtime_1.jsxs)(dialog_1.DialogFooter, { children: [(0, jsx_runtime_1.jsx)(button_1.Button, { variant: "link", onClick: onClose, children: language_1.translations.Cancel }), (0, jsx_runtime_1.jsx)(button_1.Button, { variant: "success", disabled: isError, onClick: createNewProfile, children: "Create" })] })] }) }) }));
}
exports.default = AddUserProfileModal;
